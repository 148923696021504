import React, {useContext, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import PageContext from '../../../../contexts/PageContext'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import OrderModal from './components/OrderModal'
import {getOrder, getOrders} from '../../../../api/orders'
import {DatePicker} from 'antd'
import qs from 'query-string'
import {useNavigate} from 'react-router'
import moment from 'moment'
import dayjs from 'dayjs'

function Orders() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const query = qs.parse(location.search) as Record<string, string>
  const navigate = useNavigate()

  const columns = [
    {key: 'orderId', dataIndex: 'orderId', title: '주문번호'},
    {
      dataIndex: 'deliveryTypeName',
      title: '배송 방식'
    },
    {dataIndex: ['product', 'productInfo', 'name'], title: '주문상품명'},
    {dataIndex: 'orderStatus', title: '주문상태'},
    {dataIndex: 'buyerName', title: '구매자명'},
    {dataIndex: 'amount', title: '금액'},
    {dataIndex: 'quantity', title: '수량'},
    {dataIndex: 'createdAt', title: '접수일자', render: (createdAt) => dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}
  ]

  async function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) {
        const record = await getOrder(data.orderId)
        modalRef.current.showModal({type: 'show', record})
      } else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  function onSelectFilter(key: string | string[], value: string | string[]) {
    const search: any = {...query}

    if (Array.isArray(key))
      key.forEach((keyItem, index) => {
        if (!value[index]) delete search[keyItem]
        else search[keyItem] = value[index]
      })
    else if (typeof key === 'string') {
      if (!value || value === 'null') delete search[key]
      else search[key] = value
    }
    navigate({
      pathname: location.pathname,
      search: qs.stringify(search)
    })
  }

  return (
    <>
      <BasicTable
        topButtons={[
          <>
            <DatePicker
              value={query.startDate ? (moment(query.startDate) as any) : null}
              onChange={(value) => onSelectFilter('startDate', value ? `${value.format('YYYY-MM-DD')}` : 'null')}
            />
            &nbsp;~&nbsp;
            <DatePicker
              value={query.endDate ? (moment(query.endDate) as any) : null}
              onChange={(value) =>
                onSelectFilter('endDate', value ? `${value.add(1, 'd').format('YYYY-MM-DD')}` : 'null')
              }
            />
          </>
        ]}
        showSearch
        searchPlaceHolder="주문번호 검색"
        rowKey="id"
        columns={columns}
        loading={state.loading}
        dataSource={state.data.data}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <OrderModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(Orders, getOrders)
