import React, {forwardRef, MutableRefObject, RefObject, useEffect, useState} from 'react'
import {
  Button,
  Descriptions,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
  Upload
} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../../components/common/BasicTableModal'
import dayjs from 'dayjs'
import {
  deleteVendor,
  getVendor,
  getVendors,
  IPatchVendors,
  IPostVendors,
  IVendor,
  patchVendor,
  postVendor,
  postVendorCoupon
} from '../../../../../api/vendors'
import {getPriceGroups, IPriceGroup} from '../../../../../api/priceGroups'
import {CheckOutlined, CloseOutlined, FileExcelOutlined} from '@ant-design/icons'
import ConvertCamelCase from '../../../../../libs/convertCamelCase'
import * as XLSX from 'xlsx'
import getColumnItem from '../../../../../components/getColumnItem'
import styled from 'styled-components'
import chunk from '../../../../../libs/chunk'
import {postExcel} from '../../../../../api/excels'
import {getProductBrands, IProductBrand} from '../../../../../api/productBrands'
import {getCoupons} from '../../../../../api/coupons'
import {getVendorGroups, IVendorGroup} from '../../../../../api/vendor-groups'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IVendor>
  onAction: (type: ActionType, record: Partial<IVendor>) => void | Promise<void>
}

const formLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 13}
}

const defaultKeys = [
  {key: ['vendor', 'code'], title: '납품처 코드', required: true},
  {key: ['vendor', 'brands'], title: '브랜드', required: true},
  {key: ['vendor', 'priceGroup'], title: '가격 리스트', enumVal: [''], required: true},
  {key: ['vendorGroup', 'code'], title: 'BP 코드'},
  {key: ['vendorGroup', 'name'], title: 'BP 이름'},
  {key: ['vendorGroup', 'nickname'], title: '별칭'},
  {key: ['vendorGroup', 'englishName'], title: '외국어 이름'},
  {key: ['vendorGroup', 'representativeName'], title: '대표자 성명'},
  {key: ['vendorGroup', 'businessNumber'], title: '사업자등록번호'},
  {key: ['vendorGroup', 'channel'], title: '판매채널'},
  {key: ['vendorGroup', 'englishChannel'], title: '판매채널(영문)'},
  {key: ['vendorGroup', 'grade'], title: '고객 등급'},
  {key: ['vendorGroup', 'SLPNAME'], title: 'SLPNAME'},
  {key: ['vendorGroup', 'tel'], title: '전화번호', type: 'tel'},
  {key: ['vendorGroup', 'phone'], title: '휴대폰', type: 'tel'},
  {key: ['vendorGroup', 'email'], title: '전자메일'},
  {key: ['vendorGroup', 'address'], title: 'BP 상세주소'},
  {key: ['vendorGroup', 'largeCategory'], title: '대지역'},
  {key: ['vendorGroup', 'mediumCategory'], title: '중지역'},
  {key: ['vendorGroup', 'smallCategory'], title: '소지역'},
  {key: ['vendor', 'name'], title: '납품처명'},
  {key: ['vendor', 'tel'], title: '납품처 전화번호', required: false, type: 'tel'},
  {key: ['vendor', 'phone'], title: '납품처 휴대폰 번호', required: false, type: 'tel'},
  {key: ['vendor', 'address'], title: '납품처 주소', required: false},
  {key: ['vendor', 'largeCategory'], title: '납품처 대지역', required: false},
  {key: ['vendor', 'mediumCategory'], title: '납품처 중지역', required: false},
  {key: ['vendor', 'smallCategory'], title: '납품처 소지역', required: false},
  {key: ['vendor', 'zipcode'], title: '납품처 우편번호', required: false},
  {key: ['vendorGroup', 'accountBalance'], title: '계정 잔액', type: 'number'},
  {key: ['vendorGroup', 'virtualAccount'], title: '가상계좌'},
  {key: ['vendorGroup', 'creditLimit'], title: '여신 한도'},
  {key: ['vendorGroup', 'paymentTerm'], title: '지급조건'},
  {key: ['vendorGroup', 'businessCategory'], title: '업종'},
  {key: ['vendorGroup', 'memo'], title: '비고', required: false},
  {key: ['vendor', 'manager'], title: '납품처 담당자'},
  // {key: ['vendor', 'mainCode'], title: '배송처 코드', required: false},
  {key: ['vendor', 'mainCode'], title: 'Main Code', required: false},
  {key: ['vendor', 'isPricePublic'], title: '가격공개여부', required: false},
  {key: ['vendor', 'isITE'], title: 'ITE 사용여부', required: false}
]

const error400Guide = {
  name: {name: '이름', message: '1 ~ 40자 이내로 입력하세요.'},
  zipcode: {name: '우편 번호', message: '5 ~ 6자 이내의 숫자로 입력하세요.'},
  tel: {name: '전화번호', message: '8 ~ 11자 이내의 숫자로 하이픈(-) 없이 입력하세요.'},
  phone: {name: '휴대폰 번호', message: '01X-XXX(X)-XXXX 형태로 입력해주세요.'},
  unknownProp: {name: '알 수 없음', message: '특정 속성의 입력이 올바르지 않습니다. 관리자에게 문의하세요.'}
}

function Show({record}: {record: any}) {
  const [coupons, setCoupons] = useState<any>([])
  const [selectedCoupons, setSelectedCoupons] = useState<any>(null)

  async function handleFetchCoupons() {
    try {
      const res = await getCoupons()
      setCoupons(res.data)
    } catch (e) {
      throw e
    }
  }

  async function handlePayCoupon() {
    try {
      await postVendorCoupon({couponId: selectedCoupons, id: record.id})
      setSelectedCoupons(null)
      Modal.success({title: '지급 완료', content: '쿠폰 지급이 완료되었습니다.'})
    } catch (e) {
      throw e
    }
  }

  useEffect(() => {
    handleFetchCoupons()
  }, [])

  return (
    <div style={{padding: '0 28px'}}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="이름">{record.name}</Descriptions.Item>
        <Descriptions.Item label="업체 그룹">{record.group?.name}</Descriptions.Item>
        <Descriptions.Item label="Main Code">{record.mainCode?.id}</Descriptions.Item>
        <Descriptions.Item label="ID">{record.accountId}</Descriptions.Item>
        <Descriptions.Item label="브랜드">
          {!!record?.brands?.length &&
            record?.brands.map((brand) => <Tag color={process.env.REACT_APP_THEME_COLOR}>{brand.name}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="특별 쿠폰 지급">
          <Space>
            <Select
              placeholder="쿠폰을 선택하세요"
              style={{width: 200}}
              showSearch
              filterOption={(inputVal, option: any) => {
                return option?.children.toLowerCase().includes(inputVal.toLowerCase())
              }}
              value={selectedCoupons}
              onChange={(val) => setSelectedCoupons(val)}
              allowClear
            >
              {coupons
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(
                  (coupon) =>
                    coupon.type === 'special' && <Select.Option value={coupon.id}>{coupon.name}</Select.Option>
                )}
            </Select>
            <Button disabled={!selectedCoupons} onClick={handlePayCoupon}>
              지급
            </Button>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="주소">{record.address}</Descriptions.Item>
        <Descriptions.Item label="전화번호">{record.tel}</Descriptions.Item>
        <Descriptions.Item label="휴대폰 번호">{record.phone}</Descriptions.Item>
        <Descriptions.Item label="그룹">{record.group?.name || '-'}</Descriptions.Item>
        <Descriptions.Item label="판매가 그룹">{record.priceGroup?.name || '-'}</Descriptions.Item>
        <Descriptions.Item label="상태">{record.status}</Descriptions.Item>
        <Descriptions.Item label="담당자">{record.manager}</Descriptions.Item>
        <Descriptions.Item label="가격 공개 여부">
          {record.isPricePublic ? <CheckOutlined /> : <CloseOutlined />}
        </Descriptions.Item>
        <Descriptions.Item label="ITE 주문 가능 여부">
          {record.isITE ? <CheckOutlined /> : <CloseOutlined />}
        </Descriptions.Item>
        <Descriptions.Item label="등록일">{dayjs(record.createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="수정일">{dayjs(record.updatedAt).format('YYYY-MM-DD')}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

function AddOrEdit({
  type,
  form,
  record,
  handleFinish,
  addType,
  setAddType,
  excelData,
  setExcelData,
  selectedKeys,
  setSelectedKeys,
  setInitialData,
  handleExcelReset,
  keys,
  setKeys
}) {
  const [priceGroups, setPriceGroups] = useState<IPriceGroup[]>([])
  const [brands, setBrands] = useState<IProductBrand[]>([])
  const [vendorGroups, setVendorGroups] = useState<IVendorGroup[]>([])

  useEffect(() => {
    if (excelData[0]) {
      let keys: string[] = []
      excelData.forEach((excelDataItem, index) => {
        const itemKeys = Object.keys(excelDataItem)
        if (keys.length < itemKeys.length) {
          keys = [...keys, ...itemKeys]
        }
        excelDataItem.index = index
      })
      setKeys(keys)
      setSelectedKeys(Object.keys(keys).map((defaultKey) => keys[keys.findIndex((key) => key === defaultKey)]))
    }
  }, [excelData])

  const columns = defaultKeys.map(({title, required, enumVal, type}, index) =>
    getColumnItem({selectedKeys, setSelectedKeys, setExcelData, keys, index, title, required, enumVal, type})
  )

  useEffect(() => {
    importVendorGroups()
    if (form) {
      handleGetPriceGroups()
      handleGetBrands()
      handleGetVendor()
    }
  }, [form, record])

  async function handleGetPriceGroups() {
    const {data} = await getPriceGroups()
    defaultKeys[10].enumVal = data.map(({name}) => name)
    setPriceGroups(data)
  }

  async function handleGetBrands() {
    const {data} = await getProductBrands()
    setBrands(data)
  }

  async function handleGetVendor() {
    const data = await getVendor(record.id)
    setInitialData(data)
    form.setFieldsValue({
      ...data,
      vendorGroupId: JSON.stringify(vendorGroups.find(({name}) => data.group.name === name)),
      priceGroupId: data.priceGroup.name,
      brands: data.brands?.map(({id}) => id)
    })
  }

  async function importVendorGroups() {
    let groups: any = []
    let i = 0

    while (1) {
      const {data} = await getVendorGroups({start: i * 100, perPage: 100})
      groups = [...groups, ...data]
      i++
      if (!data.length) break
    }

    setVendorGroups(groups)
  }

  function handleUploadExcel({file}) {
    if (file.status !== 'uploading') {
      const reader = new FileReader()
      handleExcelReset()
      reader.onload = function () {
        const fileData = reader.result
        const wb = XLSX.read(fileData, {type: 'binary'})
        wb.SheetNames.forEach(function (sheetName) {
          let rowObj: any = XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
          rowObj = rowObj.map((objItem) => {
            const newObj = {}
            Object.keys(objItem).forEach((key) => {
              newObj[ConvertCamelCase(key)] = objItem[key]
            })
            return newObj
          })
          setExcelData((prev) => [...prev, ...rowObj])
        })
      }

      reader.readAsBinaryString(file.originFileObj)
    }
  }

  const selectAddType = (
    <Radio.Group
      defaultValue={addType}
      onChange={(e) => {
        setAddType(e.target.value)
        handleExcelReset()
      }}
      style={{margin: '0 0 28px 0'}}
    >
      <Radio.Button value="default" defaultChecked>
        직접 입력
      </Radio.Button>
      <Radio.Button value="excel">엑셀 파일 업로드</Radio.Button>
    </Radio.Group>
  )

  const defaultAddType = (
    <>
      {type !== 'add' && (
        <Form.Item name="id" label="ID" hidden>
          <Input disabled />
        </Form.Item>
      )}

      {type === 'add' && (
        <>
          <Form.Item name="email" label="이메일">
            <Input disabled />
          </Form.Item>
          <Form.Item name="password" label="비밀번호">
            <Input disabled />
          </Form.Item>
        </>
      )}

      <Form.Item
        label="이름"
        name="name"
        rules={[
          {required: true, message: '이름를 입력해주세요'},
          {type: 'string', min: 1, max: 40, message: '1 ~ 40자 이내로 입력해주세요.'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Main Code" name={['mainCode', 'id']}>
        <InputNumber />
      </Form.Item>

      <Form.Item label="업체 그룹" name="vendorGroupId">
        <Select showSearch allowClear loading={!vendorGroups.length}>
          {vendorGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((group) => (
              <Select.Option value={JSON.stringify(group)}>{group.name}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="브랜드" name="brands" rules={[{required: true, message: '브랜드를 선택해주세요'}]}>
        <Select mode="multiple">
          {brands
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((brand) => (
              <Select.Option value={brand.id}>{brand.name}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="우편 번호" name="zipcode">
        <Input />
      </Form.Item>

      <Form.Item label="전화번호" name="tel">
        <Input />
      </Form.Item>

      <Form.Item label="휴대폰 번호" name="phone">
        <Input />
      </Form.Item>

      <Form.Item
        label="판매가 그룹"
        name="priceGroupId"
        rules={[{required: true, message: '판매가 그룹를 선택해주세요'}]}
      >
        <Select>
          {priceGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((priceGroup) => (
              <Select.Option key={priceGroup.id}>{priceGroup.name}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item label="코드" name="code" rules={[{required: true, message: '코드를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="주소" name="address" rules={[{required: true, message: '주소를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="대지역" name="largeCategory" rules={[{required: true, message: '대지역를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="중지역" name="mediumCategory" rules={[{required: true, message: '중지역를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="소지역" name="smallCategory" rules={[{required: true, message: '소지역를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="담당자명" name="manager" rules={[{required: true, message: '매니저를 입력해주세요'}]}>
        <Input />
      </Form.Item>

      <Form.Item label="활성화" name="enabled" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="ITE 사용" name="isITE" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="공급가 공개 여부" name="isPricePublic" valuePropName="checked">
        <Switch />
      </Form.Item>
    </>
  )

  const excelAddType = (
    <>
      <Space align="end" style={{margin: '10px 0 10px auto'}}>
        <Upload accept=".xls, .xlsx" onChange={handleUploadExcel} showUploadList={false}>
          <Button type="primary" shape="round">
            <FileExcelOutlined />
            엑셀 파일 업로드
          </Button>
        </Upload>
        <Button shape="round" onClick={handleExcelReset}>
          초기화
        </Button>
      </Space>
      <Table rowKey="index" columns={columns} dataSource={excelData} style={{overflowX: 'auto'}} />
    </>
  )

  return (
    <Wrapper>
      {type === 'add' && selectAddType}
      <StyledForm {...formLayout} validateTrigger={['onSubmit', 'onChange']} form={form} onFinish={handleFinish}>
        {addType === 'default' ? defaultAddType : excelAddType}
      </StyledForm>
    </Wrapper>
  )
}

const VendorModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const {title = '업체 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props
  const [addType, setAddType] = useState<'default' | 'excel'>('default')
  const [excelData, setExcelData] = useState<any[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>(['', '', '', '', ''])
  const [keys, setKeys] = useState<string[]>([])
  const [initialData, setInitialData] = useState<IVendor | null>(null)

  const columns = defaultKeys.map(({title}, index) =>
    getColumnItem({selectedKeys, setSelectedKeys, setExcelData, index, title, isError: true})
  )

  columns.push({
    dataIndex: 'error',
    title: <>에러 메세지</>,
    render: (error) => <Typography.Text type="danger">{error}</Typography.Text>
  })

  function handleExcelReset() {
    setExcelData([])
    setKeys([])
  }

  async function handleAction(type: ActionType, record) {
    if (type === 'delete') {
      await handleFinish(type, record)
    } else {
      if (!form) return
      form.submit()
    }
  }

  async function handleFinish(type: ActionType, values: IPatchVendors) {
    const {
      id,
      name,
      zipcode,
      tel,
      phone,
      priceGroupId,
      code,
      address,
      largeCategory,
      mediumCategory,
      smallCategory,
      manager,
      enabled,
      brands,
      mainCode,
      ...rest
    } = values

    async function handleDefaultAddOrEdit() {
      try {
        if (id) {
          const data: IPatchVendors = {
            name,
            zipcode,
            tel,
            phone,
            priceGroupId: +priceGroupId,
            code,
            address,
            largeCategory,
            mediumCategory,
            smallCategory,
            manager,
            brands:
              typeof brands[0] === 'number'
                ? brands.map((brand) => ({
                    id: brand
                  }))
                : brands,
            enabled,
            mainCode
          }

          if (rest.vendorGroupId) rest.vendorGroupId = JSON.parse(`${rest.vendorGroupId}`).id

          const requestBody = {...rest, ...data}
          const requestBodyEditedOnly: any = {}

          Object.keys(requestBody).forEach((key) => {
            if (initialData && (requestBody[key] || typeof requestBody[key] === 'boolean')) {
              if (
                Object.keys(initialData).includes(key) &&
                JSON.stringify(initialData[key]) !== JSON.stringify(requestBody[key])
              ) {
                requestBodyEditedOnly[key] = requestBody[key]
              } else if (!Object.keys(initialData).includes(key)) {
                requestBodyEditedOnly[key] = requestBody[key]
              }
            }
          })

          if (!requestBodyEditedOnly.mainCode?.id) delete requestBodyEditedOnly.mainCode
          await patchVendor(id, requestBodyEditedOnly)
        } else {
          const {total} = await getVendors({start: 0, perPage: 10})

          let i = total

          while (1) {
            try {
              await getVendor(i)
              i++
            } catch {
              break
            }
          }

          console.log(mainCode)

          const data: IPostVendors = {
            name,
            zipcode,
            tel,
            phone,
            priceGroupId: +priceGroupId,
            code,
            address,
            largeCategory,
            mediumCategory,
            smallCategory,
            manager,
            enabled,
            brands: brands.map((brand) => ({
              id: brand
            })),
            mainCode: mainCode?.id ? mainCode : {id: i}
          }

          if (rest.vendorGroupId) rest.vendorGroupId = JSON.parse(`${rest.vendorGroupId}`).id
          const requestBody = {...rest, ...data}
          await postVendor(requestBody)
        }
      } catch (e: any) {
        if (e.response) {
          if (e.response.status === 400) {
            const propTitles = e.response.data.message.split(', ').map((message) => message.split(' ')[0])
            Modal.error({
              title: 'Error 400',
              content: propTitles.map((title) =>
                Object.keys(error400Guide).includes(title) ? (
                  <div>{`${error400Guide[title].name}(이)가 잘못 입력되었습니다. ${error400Guide[title].message}`}</div>
                ) : (
                  <div>{error400Guide.unknownProp.message}</div>
                )
              )
            })
          } else if (e.response.data.message === 'not_found_group') {
            const data: IPatchVendors = {
              name,
              zipcode,
              tel,
              phone,
              priceGroupId: +priceGroupId,
              code,
              address,
              largeCategory,
              mediumCategory,
              smallCategory,
              manager,
              brands:
                typeof brands[0] === 'number'
                  ? brands.map((brand) => ({
                      id: brand
                    }))
                  : brands,
              enabled,
              mainCode
            }

            if (rest.vendorGroupId) rest.vendorGroupId = JSON.parse(`${rest.vendorGroupId}`).id

            const requestBody = {...rest, ...data}
            const requestBodyEditedOnly: any = {}

            Object.keys(requestBody).forEach((key) => {
              if (initialData && (requestBody[key] || typeof requestBody[key] === 'boolean')) {
                if (
                  Object.keys(initialData).includes(key) &&
                  JSON.stringify(initialData[key]) !== JSON.stringify(requestBody[key])
                ) {
                  requestBodyEditedOnly[key] = requestBody[key]
                } else if (!Object.keys(initialData).includes(key)) {
                  requestBodyEditedOnly[key] = requestBody[key]
                }
              }
            })

            if (!requestBodyEditedOnly.mainCode?.id) delete requestBodyEditedOnly.mainCode
            await patchVendor(requestBodyEditedOnly.mainCode.id, requestBodyEditedOnly)
          } else Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
        }
        throw e
      } finally {
        setLoading(false)
      }
      ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
      onAction(type, values)
    }

    async function handleExcelAdd() {
      try {
        excelData.map((dataItem) => {
          dataItem.vendor = {}
          dataItem.vendorGroup = {}
          defaultKeys.forEach(({key}, index) => {
            const temp = dataItem[selectedKeys[index]]
            // if (key[1].indexOf('tel') !== -1 || key[1].indexOf('phone') !== -1)
            //   dataItem[key[0]][key[1]] = typeof temp === 'string' ? temp?.split('-')?.join('') : '0100000000'
            // else
            if (temp) {
              if (key[1] === 'creditLimit') dataItem[key[0]][key[1]] = `${temp}`
              else if (key[1] === 'status')
                dataItem[key[0]][key[1]] = dataItem[key[0]][key[1]] = temp === 'false' ? false : true
              else if (key[1] === 'brands') dataItem[key[0]][key[1]] = [temp]
              else if (key[1] === 'code') dataItem[key[0]][key[1]] = `${temp}`
              else if (key[1] === 'zipcode') dataItem[key[0]][key[1]] = `${temp}`
              else if (key[1] === 'mainCode')
                temp ? (dataItem[key[0]][key[1]] = `${temp}`) : delete dataItem[key[0]][key[1]]
              else if (key[1] === 'isPricePublic') dataItem[key[0]][key[1]] = temp === 'X' ? false : true
              else if (key[1] === 'isITE') dataItem[key[0]][key[1]] = temp === 'X' ? false : true
              else dataItem[key[0]][key[1]] = temp
            }
          })
          dataItem.vendor.privacyPolicy = true
          return dataItem
        })

        const excelDataChunked = chunk(excelData, 10)
        for (const chunkedItem of excelDataChunked) {
          // try {
          //   await postExcel({type: 'vendor', vendorData: chunkedItem})
          // } catch (e) {}
          await postExcel({type: 'vendor', vendorData: chunkedItem})
        }

        handleExcelReset()
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      } catch (e: any) {
        Modal.error({content: `${e?.response?.status}: ${e?.response?.data?.message}`})
      }
    }

    async function handleDelete() {
      if (values.id) {
        await deleteVendor(values.id)
        ;(ref as MutableRefObject<BasicTableModalRef>).current.doneModal(type)
        onAction(type, values)
      }
    }

    setLoading(true)
    if ((type === 'add' && addType === 'default') || type === 'edit') await handleDefaultAddOrEdit()
    else if (type === 'add' && addType === 'excel') await handleExcelAdd()
    else if (type === 'delete') await handleDelete()
    setLoading(false)
  }

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={1000}
      loading={loading}
      onAction={handleAction}
      render={(type, record) => {
        if (type === 'add' || type === 'edit')
          return (
            <AddOrEdit
              type={type}
              form={form}
              record={record}
              handleFinish={(values) => handleFinish(type, values)}
              addType={addType}
              setAddType={setAddType}
              excelData={excelData}
              setExcelData={setExcelData}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              setInitialData={setInitialData}
              handleExcelReset={handleExcelReset}
              keys={keys}
              setKeys={setKeys}
            />
          )
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

export default VendorModal

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 28px;
`

const StyledForm = styled(Form)`
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
