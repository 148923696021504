import React, {useContext, useRef, useState} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import PageContext from '../../../../contexts/PageContext'
import dayjsKorean from '../../../../libs/DayjsKorean'
import {Button, Space} from 'antd'
import {getDataGroups} from '../../../../api/dataGroups'
import ImageView from '../../../../components/ImageView'
import {useNavigate} from 'react-router'
import {FolderOutlined} from '@ant-design/icons'
import DataGroupModal from './components/DataGroupModal'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'

function Datas() {
  const {state, apiHandler} = useContext(PageContext)
  const navigate = useNavigate()
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)

  const folderColumns = [
    {
      width: 30,
      dataIndex: 'image',
      render: (image) => (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {image ? (
            <ImageView src={image} width={50} height={50} />
          ) : (
            <div style={{width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <FolderOutlined style={{fontSize: 20, color: 'gray'}} />
            </div>
          )}
        </div>
      )
    },
    {
      dataIndex: 'name',
      title: <div style={{textAlign: 'center'}}>폴더명</div>,
      render: (name, {data}) => (
        <Space>
          <div>{name}</div>
        </Space>
      )
    },
    {
      title: <div style={{textAlign: 'center'}}>등록일</div>,
      dataIndex: 'createdAt',
      render: (text: string) => <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 150
    },
    {
      title: <div style={{textAlign: 'center'}}>수정일</div>,
      dataIndex: 'updatedAt',
      render: (text: string) => <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 150
    },
    {
      render: (record, _, rowIndex) => (
        <div style={{textAlign: 'center'}}>
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              setModalActions(['show', 'edit', 'delete'])
              showTableModal(e, record, rowIndex)
            }}
          >
            상세 조회 / 수정
          </Button>
        </div>
      ),
      width: 150
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  return (
    <>
      <BasicTable
        size="small"
        rowKey="id"
        topButtons={[
          <Button key="add" type="primary" shape="round" onClick={(e) => showTableModal(e)}>
            자료실 폴더 등록
          </Button>
        ]}
        columns={folderColumns}
        loading={state.loading}
        dataSource={state.data?.data?.filter(({parentId}) => !parentId).sort((a, b) => a.sortOrder - b.sortOrder)}
        total={state.data.total}
        onRow={(record: any, rowIndex) => ({
          onClick: (e) => {
            navigate('/console/etc/data/' + record.id)
          }
        })}
      />
      <DataGroupModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(Datas, getDataGroups)
